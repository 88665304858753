function DropdownList({ items, name, value, setValue }) {
    return (
        <select className="form-select" name={name} value={value} onChange={setValue}>
            {items.map((item, index) => (
                <option key={index} value={item}>
                    {item}
                </option>
            ))}
        </select>
    );
}

export default DropdownList;

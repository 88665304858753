import { useState } from "react";
import api from "../api";

const Login = ({ setToken, setClientId }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await api.login(username, password);
            if (!("token" in response && "client_id" in response)) {
                alert(response.message)
                return;
            }
            localStorage.setItem("token", response.token);
            setToken(response.token);
            localStorage.setItem("client_id", response.client_id);
            setClientId(response.client_id);
        } catch (err) {
            alert("Invalid credentials");
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="input-group">
                <input
                    type="text"
                    className="form-control"
                    value={username}
                    onChange={(event) => setUsername(event.target.value)}
                    placeholder="Username">
                </input>
                <input
                    type="password"
                    className="form-control"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    placeholder="Password">    
                </input>
                <button type="submit" className="btn btn-outline-danger">Login</button>
            </div>
        </form>
    );
}

export default Login;

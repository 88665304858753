import '../style/SurgeryTable.css';
import moment from 'moment';

function SurgeryTable({ surgeries, filter, selectedSurgery, setSelectedSurgery, deleteSurgery }) {   
    const handleRowClick = (surgery) => {
        setSelectedSurgery(surgery);
    }

    const passFilter = (surgery) => {
        const fullName = (patient) => `${patient.given_names} ${patient.surname}`;
        var eligible = true;
        eligible &= (!("surgeon" in filter) || surgery.clinician.name === filter.surgeon);
        eligible &= (!("patient_name" in filter) || fullName(surgery.patient).toLowerCase().includes(filter.patient_name.toLowerCase()));
        eligible &= (!("surgery_date" in filter) || surgery.surgery.date === filter.surgery_date);
        return eligible;
    }

    const sortByDateThenTime = (a, b) => {
        const dateA = new Date(`${a.surgery.date}T${a.surgery.time}`);
        const dateB = new Date(`${b.surgery.date}T${b.surgery.time}`);
        return dateA - dateB;
    }

    return (
        <table className="table table-dark table-striped table-hover ">
            <thead>
                <tr>
                    <th>Patient</th>
                    <th>Surgeon</th>
                    <th>Date</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {surgeries && Object.entries(surgeries).length > 0 ? 
                (
                    Object.values(surgeries).sort(sortByDateThenTime).map((surgery, index) => 
                        passFilter(surgery) ?
                        (
                            <tr key={index} onClick={() => handleRowClick(surgery)} className={selectedSurgery.surgery.id === surgery.surgery.id ? 'selected' : ''}>
                                <td>{surgery.patient.salutation} {surgery.patient.given_names} {surgery.patient.surname}</td>
                                <td>{surgery.clinician.name}</td>
                                <td>{moment(surgery.surgery.date).format("DD/MM/YYYY")}</td>
                                <td>
                                    <button
                                        type="button"
                                        style={{float: "right"}}
                                        className="btn btn-outline-secondary"
                                        onClick={(event) => deleteSurgery(surgery.surgery.id, event)}>
                                        &times;
                                    </button>
                                </td>
                            </tr>
                        ) :
                        ('')
                )
                ) :
                (
                    <tr>
                        <td colSpan={100}>No surgeries found</td>
                    </tr>
                )
                }
            </tbody>
        </table>
    );
}

export default SurgeryTable;

class BillingCode {
    constructor(description, codes) {
        this.description = description;
        this.codes = codes;
    }

    display() {
        return `${this.description}${this.codes.length === 0 ? "" : ` (${this.codes.join(', ')})`}`;
    }
}

export default BillingCode;

import { useState, useEffect } from "react";
import Surgery from "../types/Surgery";
import BillingCode from "../types/BillingCode";
import "../style/SelectedSurgery.css";
import api from '../api';
import FormGenerator from "./FormGenerator";
import DropdownList from "./DropdownList";
import Spinner from "./Spinner";

function SelectedSurgery({ selectedSurgery, setSelectedSurgery, getSurgeries, model }) {
    const [loading, setLoading] = useState(false);
    const [surgery, setSurgery] = useState(new Surgery(null));
    const [dirty, setDirty] = useState(false);

    const billing_codes = {
        "Cataract" : [
            new BillingCode("Phaco + IOL", [42702]),
            new BillingCode("IVTA", [42740]),
        ],
        "Conjunctiva" : [
            new BillingCode("Conj lesion excision, biopsy", [42695, 42680]),
            new BillingCode("Alcohol epitheliectomy", [42677]),
            new BillingCode("Conjunctiva", [42680]),
            new BillingCode("Conjunctiva, cryotherapy for melanotic lesion", [42680]),
        ],
        "Vitrectomy" : [
            new BillingCode("PPV", [42725, 42740]),
            new BillingCode("Peel", [42725]),
            new BillingCode("Heavy liquid", [42725]),
            new BillingCode("Silicone oil", [42725]),
            new BillingCode("Air", [42725]),
            new BillingCode("Gas", [42740]),
            new BillingCode("ILM Peel ", [42725]),
            new BillingCode("Yamane", []),
            new BillingCode("Suture IOL + Goretex (Luxgood lens) cv 8.0", [42707]),
        ],
        "Retinal Detachment" : [
            new BillingCode("Retinal Detachment Repair", [42725, 42779]),
            new BillingCode("Scleral Buckle", [42776]),
            new BillingCode("Laser", [42809]),
        ],
        "Other" : [
            new BillingCode("Dislocated lens (Ahreo)", [42710]),
            new BillingCode("Enucleation", [42510, 42662]),
            new BillingCode("IOL explant", [42707]),
            new BillingCode("Limbic tumour", [42695]),
            new BillingCode("Ocular transillumination", [42821]),
            new BillingCode("Plaque insertion", [42801, 42821, 42824]),
            new BillingCode("Plaque removal", [42802]),
            new BillingCode("Pterygium excision, conj autograft", [42641, 42695]),
            new BillingCode("Removal of silicone oil", [42815]),
            new BillingCode("Retinal cryotherapy", [42818]),
        ]
    };

    // Use useEffect to set initial form values from props
    useEffect(() => {
        setDirty(false);
        if (selectedSurgery) {
            setSurgery(selectedSurgery);
        } else {
            setSurgery(new Surgery(null))
        }
    }, [selectedSurgery]);

    const setSurgeryValue = (section, key, value) => {
        setDirty(dirty || surgery[section][key] !== value);
        setSurgery((prevSurgery) => ({
            ...prevSurgery,
            [section]: {
                ...prevSurgery[section],
                [key]: value,
            },
        }));
    }

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        const [section, key] = name.split('.');
        const selectValue = (type, value, checked) => {
            if (type === "checkbox") return checked;
            return ['true', 'false'].includes(value) ? value === 'true' : value;
        }
        setSurgeryValue(section, key, selectValue(type, value, checked));
    };

    class SurgerySlot {
        constructor(time, hospital, surgeon, anaesthetist) {
            this.time = time;
            this.hospital = hospital;
            this.surgeon = surgeon;
            this.anaesthetist = anaesthetist;
        }
    }

    const handleSurgeryDateChange = (event) => {
        handleChange(event);

        const surgeries_timestampToDate = (timestamp) => {
            var dateObject = new Date(timestamp);
            return dateObject.toISOString().split("T")[0];
        }
        const surgeries_createSlots = () => {
            // Hospitals
            var ads = "Adelaide Day Surgery";
            var cal = "Calvary North Adelaide";
            var wgd = "Windsor Gardens Day Surgery";
        
            // Surgeons
            var weng = "A Prof Weng Chan";
            var davd = "Dr David Sia";
        
            // Anaesthetists
            var gerry = "Dr Gerry Neumeister";
            var virgl = "Dr Virgil Niculescu";
            var james = "Dr James Dowling";
            var wilsn = "Dr Wilson Chee";
            var sheng = "Dr Sheng Lim";
        
            var slots = {};
            slots[weng] = {
                0 : new SurgerySlot("13:00", ads, weng, gerry),
                7 : new SurgerySlot("13:20", wgd, weng, james),
                10: new SurgerySlot("08:00", cal, weng, gerry),
                17: new SurgerySlot("08:00", ads, weng, virgl),
                24: new SurgerySlot("08:00", ads, weng, gerry),
            };
            slots[davd] = {
                0 : new SurgerySlot("13:20", ads, davd, sheng),
                8 : new SurgerySlot("13:20", ads, davd, wilsn),
                14: new SurgerySlot("07:50", ads, davd, sheng),
                24: new SurgerySlot("13:20", cal, davd, wilsn),
            }
            return slots;
        }
        const surgeries_slots = surgeries_createSlots();

        var surgeon = surgery.clinician.name;
        var date = Date.parse(surgery.surgery.date);
        var day = 24 * 60 * 60 * 1000;
        var week = 7 * day;
        var startDate = 1698019200000; // 23 Oct 2023
        var dayInPeriod = ((date - startDate) % (4 * week)) / day;
    
        document.querySelector("#surgeries_postOpDate").value = surgeries_timestampToDate(date + day);
        if (surgeon in surgeries_slots && dayInPeriod in surgeries_slots[surgeon]) {
            setSurgeryValue("surgery", "time", surgeries_slots[surgeon][dayInPeriod].time);
            setSurgeryValue("clinic", "name", surgeries_slots[surgeon][dayInPeriod].hospital);
            setSurgeryValue("surgery", "anaesthetist", surgeries_slots[surgeon][dayInPeriod].anaesthetist);
        } else {
            console.log("No surgery slot found for this surgeon on the chosen date")
            setSurgeryValue("surgery", "time", "");
            setSurgeryValue("clinic", "name", "");
            setSurgeryValue("surgery", "anaesthetist", "");
        }
    }

    const handleToggleChange = (event) => {
        const [section, key] = event.target.name.split('.');
        setSurgeryValue(section, key, !surgery[section][key]);
    }

    const handleBillingCodeChange = (event) => {
        const { name, checked } = event.target;
        var current_codes = surgery.surgery.billing_codes.split("|").map(item => item.trim()).filter(item => item.length > 0);
        if (checked) {
            if (!current_codes.includes(name)) {
                current_codes.push(name);
            }
        } else {
            if (current_codes.includes(name)) {
                current_codes = current_codes.filter(entry => entry !== name)
            }
        }
        setSurgeryValue("surgery", "billing_codes", current_codes.join("|"));
    }

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        console.log('Form submitted:', surgery);
        try {
            await api.setSurgery(surgery);
            await getSurgeries(false);
            setSelectedSurgery(surgery);
        } catch (error) {
            console.error("Error saving surgery:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <table className="table table-hover table-striped table-bordered">
                <colgroup>
                    <col style={{ width: '20%' }}></col>
                </colgroup>
                <tbody>
                    <tr>
                        <td>Patient</td>
                        <td>
                            <div className="input-group">
                                <DropdownList
                                    items={["Ms", "Miss", "Mr", "Mrs", "Dr", "Prof"]}
                                    name="patient.salutation"
                                    value={surgery.patient.salutation}
                                    setValue={handleChange}
                                />
                                <input
                                    id="surgeries_patientGivenNames"
                                    className="form-control"
                                    type="text"
                                    placeholder="Given name(s)"
                                    name="patient.given_names"
                                    value={surgery.patient.given_names}
                                    onChange={handleChange}>
                                </input>
                                <input
                                    id="surgeries_patientSurname"
                                    className="form-control"
                                    type="text"
                                    placeholder="Surname"
                                    name="patient.surname"
                                    value={surgery.patient.surname}
                                    onChange={handleChange}>
                                </input>
                                <div className="input-group-text">
                                    <input
                                        id="surgeries_patientConcession"
                                        className="form-check-input"
                                        type="checkbox"
                                        name="patient.concession"
                                        checked={surgery.patient.concession}
                                        onChange={handleChange}>
                                    </input>
                                    <label className="form-check-label" htmlFor="surgeries_patientConcession">
                                        Concession
                                    </label>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Sex</td>
                        <td>
                            <div className="form-check form-check-inline">
                                <input
                                    type="radio"
                                    id="surgeries_patientSexMale"
                                    className="form-check-input"
                                    value="true"
                                    name="patient.male"
                                    checked={surgery.patient.male}
                                    onChange={handleChange}>    
                                </input>
                                <label className="form-check-label" htmlFor="surgeries_patientSexMale">
                                    Male
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    type="radio"
                                    id="surgeries_patientSexFemale"
                                    className="form-check-input"
                                    value="false"
                                    name="patient.male"
                                    checked={!surgery.patient.male}
                                    onChange={handleChange}>
                                </input>
                                <label className="form-check-label" htmlFor="surgeries_patientSexFemale">
                                    Female
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Date of Birth</td>
                        <td>
                            <input
                                id="surgeries_patientDob"
                                className="form-control"
                                type="date"
                                name="patient.dob"
                                value={surgery.patient.dob}
                                onChange={handleChange}>
                            </input>
                        </td>
                    </tr>
                    <tr>
                        <td>Surgeon</td>
                        <td>
                            <DropdownList 
                                items={["A Prof Weng Chan", "Dr David Sia"]}
                                name="clinician.name"
                                value={surgery.clinician.name}
                                setValue={handleChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Eye</td>
                        <td>
                            <div className="form-check form-check-inline">
                                <input
                                    id="surgeries_eyeRight"
                                    type="radio"
                                    className="form-check-input"
                                    value="false"
                                    name="surgery.left_eye"
                                    checked={!surgery.surgery.left_eye}
                                    onChange={handleChange}>
                                </input>
                                <label className="form-check-label" htmlFor="surgeries_eyeRight">
                                    Right
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    id="surgeries_eyeLeft"
                                    type="radio"
                                    className="form-check-input"
                                    value="true"
                                    name="surgery.left_eye"
                                    checked={surgery.surgery.left_eye}
                                    onChange={handleChange}>
                                </input>
                                <label className="form-check-label" htmlFor="surgeries_eyeLeft">
                                    Left
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Procedure</td>
                        <td>
                            <div className="form-check">
                                <input
                                    id="surgeries_procedureCataract"
                                    className="form-check-input"
                                    type="checkbox"
                                    name="procedure.cataract"
                                    checked={surgery.procedure.cataract}
                                    onChange={handleChange}>    
                                </input>
                                <label htmlFor="surgeries_procedureCataract">
                                    Cataract
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    id="surgeries_procedureConjunctiva"
                                    className="form-check-input"
                                    type="checkbox"
                                    name="procedure.conjunctiva"
                                    checked={surgery.procedure.conjunctiva}
                                    onChange={handleChange}>
                                </input>
                                <label htmlFor="surgeries_procedureConjunctiva">
                                    Conjunctiva
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    id="surgeries_procedureERM"
                                    className="form-check-input"
                                    type="checkbox"
                                    name="procedure.epiretinal_membrane"
                                    checked={surgery.procedure.epiretinal_membrane}
                                    onChange={handleChange}>
                                </input>
                                <label htmlFor="surgeries_procedureERM">
                                    Epiretinal Membrane
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    id="surgeries_procedureMacularHole"
                                    className="form-check-input"
                                    type="checkbox"
                                    name="procedure.macular_hole"
                                    checked={surgery.procedure.macular_hole}
                                    onChange={handleChange}>
                                </input>
                                <label htmlFor="surgeries_procedureMacularHole">
                                    Macular Hole
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    id="surgeries_procedurePterygium"
                                    className="form-check-input"
                                    type="checkbox"
                                    name="procedure.pterygium"
                                    checked={surgery.procedure.pterygium}
                                    onChange={handleChange}>
                                </input>
                                <label htmlFor="surgeries_procedurePterygium">
                                    Pterygium
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    id="surgeries_procedureDetachment"
                                    className="form-check-input"
                                    type="checkbox"
                                    name="procedure.retinal_detachment"
                                    checked={surgery.procedure.retinal_detachment}
                                    onChange={handleChange}>
                                </input>
                                <label htmlFor="surgeries_procedureDetachment">
                                    Retinal Detachment
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    id="surgeries_procedureVitrectomy"
                                    className="form-check-input"
                                    type="checkbox"
                                    name="procedure.vitrectomy"
                                    checked={surgery.procedure.vitrectomy}
                                    onChange={handleChange}>
                                </input>
                                <label htmlFor="surgeries_procedureVitrectomy">
                                    Vitrectomy
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Billing</td>
                        <td>
                            <details>
                                <summary>Codes</summary>
                                <div>
                                    <table className="table table-hover">
                                        <tbody id="BillingCodes">
                                        {(
                                            Object.entries(billing_codes).map(([category, arr]) => (
                                                <tr key={category}>
                                                    <td>{category}</td>
                                                    <td>
                                                        {(
                                                            arr.map((obj, index) =>
                                                                <div key={index} className="form-check">
                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            name={obj.display()}
                                                                            checked={surgery.surgery.billing_codes.includes(obj.display())}
                                                                            onChange={handleBillingCodeChange}>
                                                                        </input>
                                                                        {obj.display()}
                                                                    </label>
                                                                </div>
                                                            )
                                                        )}
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </details>
                        </td>
                    </tr>
                    <tr>
                        <td>Surgery</td>
                        <td>
                            <div className="input-group">
                                <input
                                    id="surgeries_operationDate"
                                    className="form-control"
                                    type="date"
                                    name="surgery.date"
                                    value={surgery.surgery.date}
                                    onChange={handleSurgeryDateChange}>
                                </input>
                                <input
                                    id="surgeries_operationTime"
                                    className="form-control"
                                    type="time"
                                    name="surgery.time"
                                    value={surgery.surgery.time}
                                    onChange={handleChange}>
                                </input>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Hospital</td>
                        <td>
                            <div className="input-group">
                                <DropdownList 
                                    items={["Adelaide Day Surgery", "Calvary North Adelaide", "Windsor Gardens Day Surgery", "Port Pirie Regional Health Service", "Port Augusta Hospital"]}
                                    name="clinic.name"
                                    value={surgery.clinic.name}
                                    setValue={handleChange}
                                />
                                <div className="input-group-text">
                                    <input
                                        id="surgeries_printAdmission"
                                        className="form-check-input"
                                        type="checkbox">
                                    </input>
                                    <label className="form-check-label" htmlFor="surgeries_printAdmission">
                                        Print admission
                                    </label>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Anaesthetist</td>
                        <td>
                            <input
                                id="surgeries_anaesthetist"
                                className="form-control"
                                type="text"
                                list="surgeries_anaesthetists"
                                name="surgery.anaesthetist"
                                value={surgery.surgery.anaesthetist}
                                onChange={handleChange}>
                            </input>
                            <datalist id="surgeries_anaesthetists">
                                <option>Dr Gerry Neumeister</option>
                                <option>Dr Virgil Niculescu</option>
                                <option>Dr James Dowling</option>
                                <option>Dr Wilson Chee</option>
                                <option>Dr Sheng Lim</option>
                            </datalist>
                        </td>
                    </tr>
                    <tr>
                        <td>Post-Op</td>
                        <td>
                            <div className="input-group">
                                <input
                                    id="surgeries_postOpDate"
                                    className="form-control"
                                    type="date"
                                    name="postop.date"
                                    value={surgery.postop.date}
                                    onChange={handleChange}>
                                </input>
                                <input
                                    id="surgeries_postOpTime"
                                    className="form-control"
                                    type="time"
                                    name="postop.time"
                                    value={surgery.postop.time}
                                    onChange={handleChange}>
                                </input>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Post-Op Clinician</td>
                        <td>
                            <div className="input-group">
                                <DropdownList 
                                    items={["Ms Stephanie Callisto", "A Prof Weng Chan", "Dr David Sia", "Ms Emily Tran"]}
                                    name="postop.clinician_name"
                                    value={surgery.postop.clinician_name}
                                    setValue={handleChange}
                                />
                                <div className="input-group-text">
                                    <input
                                        type="radio"
                                        id="surgeries_postOpClinicPec"
                                        className="form-check-input"
                                        value="Pennington Eye Clinic"
                                        name="postop.clinic_name"
                                        checked={surgery.postop.clinic_name === "Pennington Eye Clinic"}
                                        onChange={handleChange}>
                                    </input>
                                    <label className="form-check-label" htmlFor="surgeries_postOpClinicPec">
                                        PEC
                                    </label>
                                </div>
                                <div className="input-group-text">
                                    <input
                                        type="radio"
                                        id="surgeries_postOpClinicGec"
                                        className="form-check-input"
                                        value="Gawler Eye Clinic"
                                        name="postop.clinic_name"
                                        checked={surgery.postop.clinic_name === "Gawler Eye Clinic"}
                                        onChange={handleChange}>
                                    </input>
                                    <label className="form-check-label" htmlFor="surgeries_postOpClinicGec">
                                        GEC
                                    </label>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Health Fund</td>
                        <td>
                            <div className="input-group">
                                <DropdownList 
                                    items={["Self-funding", "HCF", "Bupa", "AHSA", "Medibank Private", "NIB", "DVA"]}
                                    name="patient.health_fund"
                                    value={surgery.patient.health_fund}
                                    setValue={handleChange}
                                />
                                <div className="input-group-text">
                                    <input
                                        id="surgeries_noGap"
                                        className="form-check-input"
                                        type="checkbox"
                                        name="surgery.gap"
                                        checked={!surgery.surgery.gap}
                                        onChange={handleToggleChange}>
                                    </input>
                                    <label className="form-check-label" htmlFor="surgeries_noGap">
                                        No Gap
                                    </label>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="input-group">
                <button type="submit" className="btn btn-outline-primary" style={{marginRight: "5px", backgroundColor: dirty ? "yellow" : ""}}>Save Surgery</button>
                <FormGenerator model={model} surgery={surgery} setLoading={setLoading} />
                {loading && <Spinner />}
            </div>
        </form>
    );
}

export default SelectedSurgery;

import { useEffect, useState } from "react";
import "../style/Refresher.css";
import api from "../api";

const Refresher = ({ getSurgeries, lastUpdated }) => {
    const [outdated, setOutdated] = useState(false);

    useEffect(() => {
        // Define the task to run every 10 seconds
        const intervalId = setInterval(async () => {
            const serverLastUpdated = new Date(await api.getLastModified());
            console.log("Comparing", serverLastUpdated, lastUpdated)
            setOutdated(serverLastUpdated > lastUpdated);
        }, 10000);

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [lastUpdated]);

    const handleRefresh = async () => {
        setOutdated(false);
        getSurgeries();
    }

    return (
        <button
            type="button"
            className={`btn btn-outline-secondary ${outdated ? 'outdated' : ''}`}
            onClick={handleRefresh}>
            &#x27f2;
        </button>
    );
}

export default Refresher;

import React, { useState } from 'react';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
const path = require('path-browserify');

// Register the fonts
pdfMake.vfs = pdfFonts.pdfMake.vfs;

GlobalWorkerOptions.workerSrc = `/pdf.worker.mjs`;

const PDFUploader = () => {
  const [pdfFile, setPdfFile] = useState(path.join(__dirname, "forms", "rural_admission.pdf"));

  const convertPDFToImages = async (file) => {
    const pdf = await getDocument({ url: file }).promise; // Pass the URL to getDocument
    const numPages = pdf.numPages;
    const imagesArray = [];

    for (let i = 1; i <= numPages; i++) {
      const page = await pdf.getPage(i);
      const viewport = page.getViewport({ scale: 2 }); // Adjust scale for better resolution
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      await page.render(renderContext).promise;

      const imageData = canvas.toDataURL('image/png');
      imagesArray.push(imageData);
    }

    return imagesArray;
  };

  const handleGeneratePDF = async () => {
    if (!pdfFile) return;
    console.log("pdfFile is", pdfFile)
    const images = await convertPDFToImages(pdfFile);
    const docDefinition = {
      content: images.map((img) => ({
        image: img,
        width: 500, // Adjust width if needed
        margin: [0, 0, 0, 20], // Margin between images
      })),
    };

    pdfMake.createPdf(docDefinition).download('output.pdf');
  };

  return (
    <div>
      <button onClick={handleGeneratePDF} disabled={!pdfFile}>
        Generate PDF
      </button>
    </div>
  );
};

export default PDFUploader;

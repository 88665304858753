import Surgery from "../types/Surgery";
import api from "../api";

const NewSurgeryButton = ({ setSelectedSurgery, client_id }) => {
    const createNewSurgery = async () => {
        const next_surgery_id = await api.getNextSurgery();
        setSelectedSurgery(new Surgery({surgery_id: next_surgery_id, client_id: client_id}));
    }

    return (
        <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={createNewSurgery}>
            New
        </button>
    );
}

export default NewSurgeryButton;

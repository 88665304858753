import React from 'react';
import '../style/Spinner.css';

const Spinner = () => {
    return (
        <div className="spinner">
            <div className="loading"></div>
        </div>
    );
};

export default Spinner;

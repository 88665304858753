const get = (data, key, defaultValue=undefined) => {
    if (data != null && key in data && data[key] != null) return data[key];
    return defaultValue;
}

const if_present = (value, defaultValue) => {
    if (value.trim().length === 0) return defaultValue;
    return value;
}

class Surgery {
    constructor(data) {
        this.surgery = {
            "id": get(data, "surgery_id", -1),
            "date": get(data, "surgery_date", ""),
            "time": get(data, "surgery_time", ""),
            "left_eye": get(data, "surgery_left_eye", false),
            "anaesthetist": get(data, "surgery_anaesthetist", ""),
            "billing_codes": get(data, "surgery_billing_codes", ""),
            "gap": get(data, "surgery_gap", true),
        };
        this.postop = {
            "clinician_name": if_present(`${get(data, "postop_clinician_salutation", "")} ${get(data, "postop_clinician_given_names", "")} ${get(data, "postop_clinician_surname", "")}`, "Ms Stephanie Callisto"),
            "clinic_name": get(data, "postop_clinic_name", "Pennington Eye Clinic"),
            "date": get(data, "surgery_postop_date", ""),
            "time": get(data, "surgery_postop_time", ""),
        };
        this.patient = {
            "id": get(data, "patient_id", -1),
            "given_names": get(data, "patient_given_names", ""),
            "dob": get(data, "patient_dob", ""),
            "health_fund": get(data, "patient_health_fund", "Self-funding"),
            "surname": get(data, "patient_surname", ""),
            "salutation": get(data, "patient_salutation", "Ms"),
            "male": get(data, "patient_male", true),
            "concession": get(data, "patient_concession", false),
        };
        this.clinic = {
            "name": get(data, "clinic_name", "Adelaide Day Surgery"),
        };
        this.clinician = {
            "name": if_present(`${get(data, "clinician_salutation", "")} ${get(data, "clinician_given_names", "")} ${get(data, "clinician_surname", "")}`, "A Prof Weng Chan"),
        };
        this.procedure = {
            "cataract": get(data, "procedure_cataract", false),
            "conjunctiva": get(data, "procedure_conjunctiva", false),
            "epiretinal_membrane": get(data, "procedure_epiretinal_membrane", false),
            "macular_hole": get(data, "procedure_macular_hole", false),
            "pterygium": get(data, "procedure_pterygium", false),
            "retinal_detachment": get(data, "procedure_retinal_detachment", false),
            "vitrectomy": get(data, "procedure_vitrectomy", false),
        };
        this.client_id = get(data, "client_id", -1);
    }
}

export default Surgery;

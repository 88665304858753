import pdfMake from "pdfmake";
import {surgeries_admissionForm} from "../resources/forms/admission";
import {surgeries_dropSheet} from "../resources/forms/dropSheet";
import {surgeries_financialConsentForm} from "../resources/forms/financialConsent";
import {surgeries_preOpLetter} from "../resources/forms/preOpLetter";
import {surgeries_postOpInfoForm} from "../resources/forms/postOpInfo";
import {surgeries_quoteForms} from "../resources/forms/quotes";
import {surgeries_surgeryConsentForm} from "../resources/forms/surgeryConsent";
import {surgeries_formatDate, surgeries_getApplicableGaps, surgeries_pecOrange} from "../resources/utils";

class Surgeries_Validation {
    constructor(valid, message) {
        this.valid = valid;
        this.message = message;
    }
}

function surgeries_validateInputs(surgery) {
    var problems = [];
    var requiredFields = {
        "Patient surname" : surgery.patient.surname,
        "Patient given names" : surgery.patient.given_names,
        "Patient date of birth" : surgery.patient.dob,
        "Patient health fund" : surgery.patient.health_fund,
        "Surgeon name" : surgery.clinician.name,
        "Surgery date" : surgery.surgery.date,
        "Surgery time" : surgery.surgery.time,
        "Hospital" : surgery.clinic.name,
        "Anaesthetist" : surgery.surgery.anaesthetist,
        "Post op clinician" : surgery.postop.clinician_name,
        "Post op date" : surgery.postop.date,
        "Post op time" : surgery.postop.time,
    };
    for (const [field, value] of Object.entries(requiredFields)) !value && problems.push(` - ${field} missing`);
    
    if (surgery.postop.date && surgery.surgery.date && surgery.postop.date < surgery.surgery.date) {
        problems.push(" - Post op must be after surgery");
    }

    return new Surgeries_Validation(problems.length === 0, problems.join("\n"));
}

function surgeries_displayGaps(surgery) {
    var result = "";
    let allowGap = !document.querySelector('#surgeries_noGap').checked;
    for (let gap of surgeries_getApplicableGaps(surgery)) result += gap.display(allowGap) + "\n";
    document.querySelector('#surgeries_gapFee').innerText = result;
}

const getNewDoc = () => {
    // A4: 595.35 x 841.995 pt
    return {
        pageSize: 'A4',
        pageMargins: [40, 60, 40, 15],
        defaultStyle: {
            fontSize: 13,
        },
        styles: {
            h1: {
                alignment: 'center',
                bold: true,
                fontSize: 15,
            },
            h2: {
                bold: true,
                fontSize: 13,
                color: surgeries_pecOrange,
            },
            form_med: {
                fontSize: 12,
                color: 'black',
            },
            form_small: {
                fontSize: 10,
                color: 'black',
            },
            tableHeader : {
                bold: true,
            },
        },
        content: []
    };
}

const FormGenerator = ({ model, surgery, setLoading }) => {
    const generateForms = async () => {
        setLoading(true);
        try {
            surgery.print_admission = document.querySelector("#surgeries_printAdmission").checked;
            console.log("Generating forms for surgery", surgery)
            const doc = getNewDoc();
            const rural = ["Port Pirie Regional Health Service", "Port Augusta Hospital"].includes(surgery.clinic.name);
            if (!rural) {
                var validation = surgeries_validateInputs(surgery);
                console.log(validation)
                if (!validation.valid) {
                    alert(validation.message);
                    return;
                }
            }
        
            if (!rural) {
                await surgeries_preOpLetter(doc, model, surgery);
                surgeries_dropSheet(doc, surgery);
                surgeries_financialConsentForm(doc, surgery);
                surgeries_postOpInfoForm(doc, surgery);
            }
            await surgeries_surgeryConsentForm(doc, surgery);
            await surgeries_admissionForm(doc, surgery);
            if (!rural) surgeries_quoteForms(doc, surgery);
            pdfMake.createPdf(doc).download(`surgery_forms_${surgeries_formatDate(undefined, 4)}.pdf`);
        } catch (error) {
            console.error("Error generating forms:", error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <button type="button" className="btn btn-outline-primary" onClick={generateForms}>Generate Forms</button>
            <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                <span className="visually-hidden">Toggle Dropdown</span>
            </button>
            <ul className="dropdown-menu">
                <li><button type="button" className="dropdown-item" onClick={generateForms}>Surgery forms</button></li>
                <li><button type="button" className="dropdown-item" disabled={true}>Rural surgery forms</button></li>
            </ul>
        </>
    );
}

export default FormGenerator;

import { useState } from "react";
import Refresher from "./Refresher";
import NewSurgeryButton from "./NewSurgeryButton";

const Filter = ({ setFilter, getSurgeries, lastUpdated, setSelectedSurgery, client_id }) => {
    const [inputs, setInputs] = useState({
        patient_name: "",
        surgeon: "All",
        surgery_date: "",
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        const newInputs = {
            ...inputs,
            [name]: value,
        };
        setInputs(newInputs);

        var newFilter = {};
        if (newInputs.surgeon !== "All") newFilter["surgeon"] = newInputs.surgeon;
        if (newInputs.patient_name !== "") newFilter["patient_name"] = newInputs.patient_name;
        if (newInputs.surgery_date !== "") newFilter["surgery_date"] = newInputs.surgery_date;
        setFilter(newFilter);
    }

    return (
        <div className="input-group">
            <input
                className="form-control"
                type="text"
                name="patient_name"
                value={inputs.patient_name}
                placeholder='Patient name'
                onChange={handleChange}>    
            </input>
            <select
                className="form-select"
                name="surgeon"
                value={inputs.surgeon}
                onChange={handleChange}>
                <option value="All">All surgeons</option>
                <option value="A Prof Weng Chan">A Prof Weng Chan</option>
                <option value="Dr David Sia">Dr David Sia</option>
            </select>
            <input
                className="form-control"
                type="date"
                name="surgery_date"
                value={inputs.surgery_date}
                onChange={handleChange}>
            </input>
            <NewSurgeryButton setSelectedSurgery={setSelectedSurgery} client_id={client_id} />
            <Refresher getSurgeries={getSurgeries} lastUpdated={lastUpdated} />
        </div>
    );
}

export default Filter;

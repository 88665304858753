import React, { useEffect, useState } from 'react';
import './style/App.css';
import Surgery from "./types/Surgery";
import api from './api';
import SurgeryTable from './components/SurgeryTable';
import SelectedSurgery from './components/SelectedSurgery';
import Filter from './components/Filter';
import Login from './components/Login';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import PDFUploader from './components/PdfUploader';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const App = () => {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [clientId, setClientId] = useState(localStorage.getItem('client_id'));
    const [surgeries, setSurgeries] = useState({});
    const [selectedSurgery, setSelectedSurgery] = useState(new Surgery(null));
    const [filter, setFilter] = useState({});
    const [lastUpdated, setLastUpdated] = useState(new Date(0));
    const [model, setModel] = useState({ "clinics": [] });

    useEffect(() => {
        setModel((prevModel) => ({
            ...prevModel,
            "clinics": api.getClinics(clientId),
        }));
    }, [clientId])

    const scrollToElementById = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const getSurgeries = async (scroll=true) => {
        const result = await api.getSurgeries(clientId);
        setSurgeries(result);
        setLastUpdated(new Date());
        scroll && scrollToElementById("surgery_table");
    }

    const deleteSurgery = async (id, event) => {
        event.stopPropagation();
        const confirmed = window.confirm('Are you sure you want to delete this surgery?');
        if (confirmed) {
            console.log("Deleting Surgery", id)
            await api.deleteSurgery(id);
            await getSurgeries(false);
            if (selectedSurgery.surgery.id === id) setSelectedSurgery(new Surgery(null));
        }
    }

    return (
        <div className="App">
            <header className="App-header">
                <h1>Surgery Manager</h1>
                <SwitchTransition>
                    <CSSTransition
                        key={token ? 'loggedIn' : 'loggedOut'}
                        timeout={200}
                        classNames="fade">
                        <div style={{height: "200px", marginTop: "10px"}}>
                            {
                                token ?
                                <button
                                    type='button'
                                    className='btn btn-outline-danger btn-lg'
                                    onClick={getSurgeries}>
                                    Get Surgeries
                                </button> :
                                <Login setToken={setToken} setClientId={setClientId} />
                            }
                        </div>
                    </CSSTransition>
                </SwitchTransition>
            </header>
            {
                token &&
                <div className='container'>
                    <div id="surgery_table" className='left'>
                        <Filter setFilter={setFilter} getSurgeries={getSurgeries} lastUpdated={lastUpdated} setSelectedSurgery={setSelectedSurgery} client_id={clientId} />
                        <SurgeryTable surgeries={surgeries} filter={filter} selectedSurgery={selectedSurgery} setSelectedSurgery={setSelectedSurgery} deleteSurgery={deleteSurgery} />
                    </div>
                    <div className='right'>
                        <SelectedSurgery selectedSurgery={selectedSurgery} setSelectedSurgery={setSelectedSurgery} getSurgeries={getSurgeries} model={model} />
                    </div>
                </div>
            }
        </div>
    );
}

export default App;
